import { graphql } from "gatsby";
import Home from "../templates/home";

const HomePage = ({
	data: {
		craft: { entry },
	},
}) => <Home entry={entry} />;

export const pageQuery = graphql`
	query homePage {
		craft {
			entry(slug: "home-page") {
				... on CraftCMS_homePage_homePage_Entry {
					heroTitle
					heroText
					heroImage {
						width
						height
						url
						title
						imagerX(transform: "maxWidth-2560") {
							width
							height
							url
						}
						imagerXWp: imagerX(transform: "maxWidth-2560-wp") {
							width
							height
							url
						}
					}
					heroImageMobile {
						width
						height
						url
						title
						imagerX(transform: "maxWidth-2560") {
							width
							height
							url
						}
						imagerXWp: imagerX(transform: "maxWidth-2560-wp") {
							width
							height
							url
						}
					}
					heroImageLink {
						element {
							slug
							uri
						}
						ariaLabel
						customText
						target
						text
						title
						type
						url
					}
					heroVideo {
						id
						url
						title
					}
					ratesTitle
					ratesBlocks {
						... on CraftCMS_ratesBlocks_ratesBlock_BlockType {
							id
							ratesBlockTitle
							ratesBlockFigure
							ratesBlockText
						}
					}
					featuredBlocks {
						... on CraftCMS_featuredBlocks_BlockType {
							heading
							button {
								ariaLabel
								customText
								element {
									slug
									uri
								}
								target
								text
								title
								type
								url
							}
							buttonStyle
							buttonSubtext
							blocks {
								... on CraftCMS_blocks_content_BlockType {
									background
									heading
									text
									modalLink {
										element {
											slug
											uri
										}
										ariaLabel
										customText
										target
										text
										title
										type
										url
									}
								}
							}
						}
					}
					ctaImageSection {
						... on CraftCMS_ctaImageSection_BlockType {
							heading
							text
							highlight
							image {
								width
								height
								url
								title
								imagerX(transform: "maxWidth-2560") {
									width
									height
									url
								}
								imagerXWp: imagerX(transform: "maxWidth-2560-wp") {
									width
									height
									url
								}
							}
							button {
								element {
									slug
									uri
								}
								ariaLabel
								customText
								target
								text
								title
								type
								url
							}
						}
					}
					featuredSlider {
						... on CraftCMS_featuredSlider_BlockType {
							subHeading
							text
							heading
							highlightText
							backgroundImage {
								width
								height
								url
								title
								imagerX(transform: "maxWidth-2560") {
									width
									height
									url
								}
								imagerXWp: imagerX(transform: "maxWidth-2560-wp") {
									width
									height
									url
								}
							}
							button {
								element {
									slug
									uri
								}
								ariaLabel
								customText
								target
								text
								title
								type
								url
							}
						}
					}
					calculatorDisclaimer
					listTextCta {
						... on CraftCMS_listTextCta_BlockType {
							text
							heading
							button {
								ariaLabel
								customText
								element {
									slug
									uri
								}
								target
								text
								title
								type
								url
							}
						}
					}
					testimonials {
						... on CraftCMS_testimonials_BlockType {
							backgroundImage {
								title
								width
								height
								url
								imagerX(transform: "maxWidth-1920") {
									width
									height
									url
								}
								imagerXWp: imagerX(transform: "maxWidth-1920-wp") {
									width
									height
									url
								}
							}
							testimonials {
								... on CraftCMS_testimonials_default_Entry {
									date
									title
									richtext
									cite
								}
							}
						}
					}
					ctaSection {
						... on CraftCMS_ctaSection_BlockType {
							id
							heading
							animation
							text
							theme(label: false)
							button {
								ariaLabel
								customText
								element {
									slug
									uri
								}
								target
								text
								title
								type
								url
							}
						}
					}
					metaTitle
					metaDescription
					metaShareImage {
						filename
						url
					}
				}
			}
		}
	}
`;

export default HomePage;
