import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import FeaturedSlider from "../components/FeaturedSlider";
import HeroHome from "../components/HeroHome";
import RowFeaturedBlocks from "../components/RowFeaturedBlocks";
import TrustpilotSection from "../components/TrustpilotSection";
import CalculatorBorrowSimpleUpdate from "../components/CalculatorBorrowSimpleUpdate";
import CtaSection from "../components/CtaSection";
import CtaImageSection from "../components/CtaImageSection";
import ListTextCtaSection from "../components/ListTextCtaSection";

export default function Home({ entry }) {
	const {
		heroText,
		heroCta,
		heroImage,
		heroImageMobile,
		heroImageLink,
		heroVideo,
		featuredSlider,
		listTextCta,
		testimonials,
		ctaSection,
		ctaImageSection,
		featuredBlocks,
		calculatorDisclaimer,
		metaTitle,
		metaDescription,
		metaShareImage,
	} = entry ?? [];

	const ctaSectionData = ctaSection?.[0];
	const ctaImageSectionData = ctaImageSection?.[0];
	const listTextCtaData = listTextCta?.[0];
	const featuredBlocksData = featuredBlocks?.[0];

	// NOTE(jordan): Micro-transform
	// - consolidate extra supertable fields into one button object
	featuredBlocksData.button.subtext = featuredBlocksData.buttonSubtext;
	featuredBlocksData.button.style   = featuredBlocksData.buttonStyle;
	delete featuredBlocksData.buttonSubtext;
	delete featuredBlocksData.buttonStyle;

	return (
		<Layout className="layout--home">
			<SEO
				title={metaTitle}
				description={metaDescription}
				image={metaShareImage}
			/>

			<HeroHome
				text={heroText}
				image={heroImage}
				mobileImage={heroImageMobile}
				imageLink={heroImageLink}
				video={heroVideo}
				heroCta={heroCta}
				colorTheme="dark"
			/>

			<RowFeaturedBlocks
				colorTheme="dark"
				data={featuredBlocksData}
			/>

			<FeaturedSlider
				data={featuredSlider}
				colorTheme="dark"
			/>

			<CalculatorBorrowSimpleUpdate
				colorTheme="dark"
				nextTheme="light"
				disclaimer={calculatorDisclaimer}
			/>

			<ListTextCtaSection
				data={listTextCtaData}
			/>

			<TrustpilotSection
				data={testimonials}
				colorTheme="dark"
			/>

			<CtaImageSection
				colorTheme="dark"
				data={ctaImageSectionData}
			/>

			<CtaSection
				colorTheme="dark"
				data={ctaSectionData}
				noPadding={true}
			/>
		</Layout>
	);
}
